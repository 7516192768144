import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const routes = [
	{
		path: '/',
		redirect: '/home',
	}, {
		path: '/login',
		component: () => import('@/views/login/login.vue'),
		meta: {
			title: '登录'
		}
	}, {
		path: '/register',
		component: () => import('@/views/login/register.vue'),
		meta: {
			title: '注册'
		}
	}, {
		path: '/home',
		name: 'Home',
		component: () => import('@/views/front/Home.vue'),
		meta: {
			title: '首页'
		}
	}, {
		path: '/about',
		name: 'About',
		component: () => import('@/views/front/About.vue'),
		meta: {
			title: '关于我们'
		}
	}, {
		path: '/news',
		name: 'News',
		component: () => import('@/views/front/News.vue'),
		meta: {
			title: '新闻资讯'
		}
	}, {
		path: '/detail/:id',
		name: 'Detail',
		component: () => import('@/views/front/Detail.vue'),
		meta: {
			title: '新闻详情'
		}
	}, {
		path: '/prod',
		name: 'Prod',
		component: () => import('@/views/front/Prod.vue'),
		meta: {
			title: '产品服务'
		}
	}, {
		path: '/info',
		name: 'Info',
		component: () => import('@/views/front/Info.vue'),
		meta: {
			title: '联系我们'
		}
	}, {
		path: '/admin',
		name: 'Admin',
		component: () => import('@/views/admin/Index.vue'),
		meta: {
			title: '后台首页'
		},
		// children: [
		// 	{
		// 		path: '/info',
		// 		name: 'Info',
		// 		component: () => import('@/views/admin/Info.vue'),
		// 		meta: {
		// 			title: '基本信息'
		// 		}
		// 	}
		// ]
	},
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
  if (to.name === 'Detail') {
    window.scrollTo(0, 0); // 将页面滚动到顶部
  }
  next();
});

export default router
