<template>
	<div id="app">
		<Nav class="page_nav page_gs_zero" />
		<Banner class="page_banner page_gs_zero" />
		<router-view/>
		<FooterV1 class="page_gs_zero"/>
	</div>
</template>

<script>
import Nav from '@/views/front/components/Nav.vue';
import Banner from '@/views/front/components/Banner';
import FooterV1 from '@/views/front/components/FooterV1';

export default{
	name: 'App',
	components: { Nav, Banner, FooterV1 }
}
</script>

<style lang="scss">
#app{
	.page_gs_zero{
		flex-shrink: 0;
		flex-grow: 0;
	}
}
body{
	min-width: 320px;
}
</style>
